var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"purchasing-management-page-box"},[_c('FormSearch',{attrs:{"getdata":_vm.getdata,"form-inline":_vm.addPermission === 'transactionData_addPurchasing'
        ? _vm.sellFormInline
        : _vm.purchaseFormInline,"form-item-arr":_vm.formItemArr,"is-activated":false}}),_c('div',{staticClass:"page-container-table"},[_c('div',{staticClass:"list-operation"},[_c('el-button',{directives:[{name:"isPermission",rawName:"v-isPermission",value:(_vm.addPermission),expression:"addPermission"}],attrs:{"size":"small","type":"primary"},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.addPermission === "transactionData_addPurchasing" ? "新增采购订单" : "新增销售订单")+" ")]),_c('el-button',{directives:[{name:"isPermission",rawName:"v-isPermission",value:(_vm.exportPermission),expression:"exportPermission"}],attrs:{"size":"small"},on:{"click":_vm.exportData}},[_vm._v(" 导出 ")])],1),_c('div',{directives:[{name:"isPermission",rawName:"v-isPermission",value:(_vm.viewPermission),expression:"viewPermission"}]},[_c('Table',{attrs:{"operation-button":_vm.operationButton,"item-data":_vm.itemData,"list-data":_vm.listData}}),(_vm.addPermission === 'transactionData_addPurchasing')?_c('Pagination',{attrs:{"params":_vm.sellFormInline,"total":_vm.total,"in-article":_vm.listData.length,"get-data-list":_vm.getdata}}):_c('Pagination',{attrs:{"params":_vm.purchaseFormInline,"total":_vm.total,"in-article":_vm.listData.length,"get-data-list":_vm.getdata}})],1)]),_c('el-dialog',{attrs:{"title":"提货记录","visible":_vm.dialogVisible},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[(
        _vm.detailsObj.historyGoodsDetailList &&
          _vm.detailsObj.historyGoodsDetailList.length > 0
      )?_c('Table',{attrs:{"item-data":_vm.deliveryRecordArr,"list-data":_vm.detailsObj.historyGoodsDetailList,"overflow-tooltip":false,"table-height":"246"}}):_vm._e(),(
        _vm.detailsObj.historyGoodsDetailList &&
          _vm.detailsObj.historyGoodsDetailList.length > 0 &&
          _vm.currGoodsDetailList.length > 0
      )?_c('p',{class:[
        'tip-box',
        _vm.detailsObj.historyGoodsDetailList &&
          _vm.detailsObj.historyGoodsDetailList.length > 0
          ? ''
          : 'no-padding-top',
      ]},[_c('i',{staticClass:"el-icon-bell"}),_vm._v(" 系统检测到商品数据已更新，请根据最新的商品数据进行维护 ")]):_vm._e(),(_vm.currGoodsDetailList.length > 0)?_c('Table',{attrs:{"item-data":_vm.deliveryRecordArr,"list-data":_vm.currGoodsDetailList,"table-height":"300","overflow-tooltip":false},scopedSlots:_vm._u([{key:"deliveryCode",fn:function({ row }){return [_c('el-input',{attrs:{"placeholder":"请输入","maxlength":30},on:{"change":function($event){return _vm.enterContent(row)}},model:{value:(row.deliveryCode),callback:function ($$v) {_vm.$set(row, "deliveryCode", $$v)},expression:"row.deliveryCode"}})]}},{key:"deliveryDate",fn:function({ row }){return [_c('el-date-picker',{attrs:{"type":"date","value-format":"yyyy-MM-dd","placeholder":"选择日期"},model:{value:(row.deliveryDate),callback:function ($$v) {_vm.$set(row, "deliveryDate", $$v)},expression:"row.deliveryDate"}})]}}],null,false,2666517903)}):_vm._e(),_c('div',{staticClass:"button-assembly-box"},[(_vm.currGoodsDetailList.length > 0)?_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.addPickupRecord}},[_vm._v(" 确定 ")]):_vm._e(),_c('el-button',{attrs:{"size":"small"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" 取消 ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }