import { getRequest, postRequest } from './index'
// 订单管理页面
// 订单管理分页
export const orderInfoPage = (data, successCallback, failureCallback) => {
  postRequest('importOrder/orderInfoPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单管理导出
export const orderInfoExport = (data, successCallback, failureCallback) => {
  postRequest('importOrder/orderInfoExport', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单信息上传合同附件
export const orderInfoUploadContractFile = (id, data, successCallback, failureCallback) => {
  postRequest(`importOrder/orderInfoUploadContractFile/${id}`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 导入订单信息接口
export const importOrder = (data, successCallback, failureCallback) => {
  postRequest(`/importOrder/importOrder`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单详情
export const orderInfoDetail = (id, successCallback, failureCallback) => {
  getRequest(`/importOrder/orderInfoDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单入库明细分页
export const inDetailPage = (data, successCallback, failureCallback) => {
  postRequest('impOrderInventoryIn/inDetailPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单入库明细详情
export const inDetail = (id, successCallback, failureCallback) => {
  getRequest(`impOrderInventoryIn/inDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单出库明细分页
export const outDetailPage = (data, successCallback, failureCallback) => {
  postRequest('impOrderInventoryOut/outDetailPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单出库明细详情
export const outDetail = (id, successCallback, failureCallback) => {
  getRequest(`impOrderInventoryOut/outDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单开票信息分页
export const impOrderInvoice = (data, successCallback, failureCallback) => {
  postRequest('impOrderInvoice/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单开票信息详情
export const impOrderInvoiceDetail = (id, successCallback, failureCallback) => {
  getRequest(`impOrderInvoice/queryByDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单资金收支明细分页
export const impOrderFundFlow = (data, successCallback, failureCallback) => {
  postRequest('impOrderFundFlowDetail/queryByPage', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单资金收支明细详情
export const impOrderFundFlowDetail = (id, successCallback, failureCallback) => {
  getRequest(`impOrderFundFlowDetail/queryByDetail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易企业分页列表
export const pageTransactionCmpInfo = (data, successCallback, failureCallback) => {
  postRequest('data/cmp/info/pageTransactionCmpInfo', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易企业新增
export const addTransactionCmpInfo = (data, successCallback, failureCallback) => {
  postRequest('data/cmp/info/addTransactionCmpInfo', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易企业编辑
export const updateTransactionCmpInfo = (data, successCallback, failureCallback) => {
  postRequest('data/cmp/info/updateTransactionCmpInfo', data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易企业编辑
export const deleteTransactionCmpInfo = (id, successCallback, failureCallback) => {
  getRequest(`data/cmp/info/deleteTransactionCmpInfo/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息分页
export const pageExchangeInfo = (data, successCallback, failureCallback) => {
  postRequest(`data/exchange/info/pageExchangeInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息新增
export const addExchangeInfo = (data, successCallback, failureCallback) => {
  postRequest(`data/exchange/info/addExchangeInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息编辑
export const updateExchangeInfo = (data, successCallback, failureCallback) => {
  postRequest(`data/exchange/info/updateExchangeInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易用户模糊搜索
export const getTransactionCmpInfo = (data, successCallback, failureCallback) => {
  postRequest(`data/cmp/info/getTransactionCmpInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息导出
export const exportExchangeInfo = (data, successCallback, failureCallback) => {
  postRequest(`data/exchange/info/exportExchangeInfo`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息详情
export const detailExchangeInfo = (id, successCallback, failureCallback) => {
  getRequest(`data/exchange/info/detailExchangeInfo/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易信息删除
export const deleteExchangeInfo = (id, successCallback, failureCallback) => {
  getRequest(`data/exchange/info/deleteExchangeInfo/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易合同编号模糊搜索
export const getExchangeDetail = (data, successCallback, failureCallback) => {
  postRequest(`data/exchange/info/getExchangeDetail`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 交易合同编号模糊搜索
export const addDeliveryGoods = (data, successCallback, failureCallback) => {
  postRequest(`data/delivery/goods/detail/addDeliveryGoods`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
