<template>
  <!-- 采购管理 销售管理 -->
  <div class="purchasing-management-page-box">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="
        addPermission === 'transactionData_addPurchasing'
          ? sellFormInline
          : purchaseFormInline
      "
      :form-item-arr="formItemArr"
      :is-activated="false"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <!-- v-isPermission="addPermission" -->
        <el-button
          v-isPermission="addPermission"
          size="small"
          type="primary"
          @click="add"
        >
          {{
            addPermission === "transactionData_addPurchasing"
              ? "新增采购订单"
              : "新增销售订单"
          }}
        </el-button>
        <el-button
          v-isPermission="exportPermission"
          size="small"
          @click="exportData"
        >
          导出
        </el-button>
      </div>
      <div v-isPermission="viewPermission">
        <Table
          :operation-button="operationButton"
          :item-data="itemData"
          :list-data="listData"
        />
        <!-- 分页 -->
        <Pagination
          v-if="addPermission === 'transactionData_addPurchasing'"
          :params="sellFormInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
        <Pagination
          v-else
          :params="purchaseFormInline"
          :total="total"
          :in-article="listData.length"
          :get-data-list="getdata"
        />
      </div>
    </div>
    <!-- 提货记录弹出窗 -->
    <el-dialog title="提货记录" :visible.sync="dialogVisible">
      <Table
        v-if="
          detailsObj.historyGoodsDetailList &&
            detailsObj.historyGoodsDetailList.length > 0
        "
        :item-data="deliveryRecordArr"
        :list-data="detailsObj.historyGoodsDetailList"
        :overflow-tooltip="false"
        table-height="246"
      />
      <p
        v-if="
          detailsObj.historyGoodsDetailList &&
            detailsObj.historyGoodsDetailList.length > 0 &&
            currGoodsDetailList.length > 0
        "
        :class="[
          'tip-box',
          detailsObj.historyGoodsDetailList &&
            detailsObj.historyGoodsDetailList.length > 0
            ? ''
            : 'no-padding-top',
        ]"
      >
        <i class="el-icon-bell" />
        系统检测到商品数据已更新，请根据最新的商品数据进行维护
      </p>
      <Table
        v-if="currGoodsDetailList.length > 0"
        :item-data="deliveryRecordArr"
        :list-data="currGoodsDetailList"
        table-height="300"
        :overflow-tooltip="false"
      >
        <!-- 提单编号 -->
        <template #deliveryCode="{ row }">
          <el-input
            v-model="row.deliveryCode"
            placeholder="请输入"
            :maxlength="30"
            @change="enterContent(row)"
          />
        </template>
        <!-- 提货日期 -->
        <template #deliveryDate="{ row }">
          <el-date-picker
            v-model="row.deliveryDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </template>
      </Table>
      <div class="button-assembly-box">
        <el-button
          v-if="currGoodsDetailList.length > 0"
          size="small"
          type="primary"
          @click="addPickupRecord"
        >
          确定
        </el-button>
        <el-button size="small" @click="dialogVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { timestampToTime } from '@/utils/util'
import {
  addDeliveryGoods,
  pageExchangeInfo,
  detailExchangeInfo,
  deleteExchangeInfo,
  exportExchangeInfo
} from '@/api/transactionData'
export default {
  components: {
    FormSearch,
    Table,
    Pagination
  },
  data() {
    return {
      dialogVisible: false,
      formItemArr: [
        { type: 'input', label: '合同编号', value: 'contractCode' },
        { type: 'input', label: '采购方', value: 'purchaseCmpName' },
        { type: 'input', label: '销售方', value: 'saleCmpName' },
        {
          type: 'picker',
          label: '签订日期',
          value: 'inDateArr',
          startTimer: 'signDateStart',
          endTimer: 'signDateEnd'
        }
      ],
      operationButton: [],
      itemData: [
        // 表格
        { label: '合同编号', prop: 'contractCode', width: 180 },
        { label: '采购方', prop: 'purchaseCmpName', width: 180 },
        { label: '销售方', prop: 'saleCmpName', width: 180 },
        { label: '合同金额', prop: 'totalAmount', width: 180, type: 'money' },
        { label: '签订日期', prop: 'signDate', width: 180 },
        { label: '签订地点', prop: 'cityName', width: 180 },
        { label: '创建人', prop: 'createUser', width: 180 },
        { label: '创建时间', prop: 'createTime', width: 180 }
      ],
      deliveryRecordArr: [
        // 提货记录
        { label: '货物名称', prop: 'deliveryGoodsName' },
        { label: '品牌', prop: 'deliveryGoodsBrand' },
        { label: '型号规格', prop: 'deliveryGoodsMaterial' },
        {
          label: '单位',
          prop: 'deliveryGoodsUnit',
          child: this.$store.getters.getDictionaryItem('purchaseUnit')
        },
        { label: '合同数量', prop: 'contractQuantity' },
        { label: '提单编号', prop: 'deliveryCode', width: 160 },
        { label: '提货日期', prop: 'deliveryDate', width: 160 }
      ],
      detailsObj: {},
      listData: [],
      sellFormInline: {
        pageNum: 1,
        pageSize: 10
      },
      purchaseFormInline: {
        pageNum: 1,
        pageSize: 10
      },
      formInline: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      currGoodsDetailList: [],
      addPermission: '',
      exportPermission: '',
      viewPermission: ''
    }
  },
  watch: {
    // 路由判断属于哪一个的页面
    '$route.path': {
      handler(newVal) {
        switch (newVal) {
          case '/transactionData/purchasingManagement':
            this.operationButton = [
              {
                permission: 'transactionData_editPurchasing',
                bType: 'primary',
                label: '编辑',
                handleEvent: this.edit
              },
              {
                permission: 'transactionData_purchasingDetails',
                bType: 'primary',
                label: '详情',
                handleEvent: this.view
              },
              {
                permission: 'transactionData_purchasing_deliveryRecord',
                bType: 'primary',
                label: '提货记录',
                handleEvent: this.deliveryRecord
              },
              {
                permission: 'transactionData_deletePurchasing',
                bType: 'primary',
                label: '删除',
                handleEvent: this.deleteData
              }
            ]
            this.addPermission = 'transactionData_addPurchasing'
            this.exportPermission = 'transactionData_exportPurchasing'
            this.viewPermission = 'transactionData_purchasingManagement_view'
            this.getdata(true)
            break
          case '/transactionData/marketManagement':
            this.operationButton = [
              {
                permission: 'transactionData_editMarket',
                bType: 'primary',
                label: '编辑',
                handleEvent: this.edit
              },
              {
                permission: 'transactionData_marketDetails',
                bType: 'primary',
                label: '详情',
                handleEvent: this.view
              },
              {
                permission: 'transactionData_market_deliveryRecord',
                bType: 'primary',
                label: '提货记录',
                handleEvent: this.deliveryRecord
              },
              {
                permission: 'transactionData_deleteMarket',
                bType: 'primary',
                label: '删除',
                handleEvent: this.deleteData
              }
            ]
            this.addPermission = 'transactionData_addMarket'
            this.exportPermission = 'transactionData_exportMarket'
            this.viewPermission = 'transactionData_marketManagement_view'
            this.getdata(true)
            break
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 新增提货记录
    addPickupRecord() {
      const params = {
        id: this.detailsObj.id,
        goodsAddReqList: [...this.currGoodsDetailList]
      }
      addDeliveryGoods(params, () => {
        this.$message.success('成功')
        this.dialogVisible = false
        this.getdata(true)
      })
    },
    // 销售管理采购管理相关操作
    // 编辑
    edit({ id }) {
      this.$router.push({
        path:
          this.addPermission === 'transactionData_addPurchasing'
            ? '/transactionData/editPurchasing'
            : '/transactionData/editMarket',
        query: { id }
      })
    },
    // 新增
    add() {
      this.$router.push({
        path:
          this.addPermission === 'transactionData_addPurchasing'
            ? '/transactionData/addPurchasing'
            : '/transactionData/addMarket'
      })
    },
    // 删除
    deleteData({ id }) {
      this.$confirm('删除后，本条数据将无法恢复', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error'
      })
        .then(() => {
          deleteExchangeInfo(id, () => {
            this.$message.success('成功')
            this.getdata()
          })
        })
        .catch(() => {})
    },
    // 查看详情
    view({ id }) {
      this.$router.push({
        path:
          this.addPermission === 'transactionData_addPurchasing'
            ? '/transactionData/purchasingDetails'
            : '/transactionData/marketDetails',
        query: { id }
      })
    },
    // 提货记录
    deliveryRecord({ id }) {
      detailExchangeInfo(id, (res) => {
        this.detailsObj = { ...res.data }
        this.currGoodsDetailList = this.detailsObj.currGoodsDetailList
          ? [...this.detailsObj.currGoodsDetailList]
          : []
        this.dialogVisible = true
      })
    },
    // 导出数据
    exportData() {
      if (this.total === 0) {
        this.$message.error('没有可导出的数据')
        return
      }
      if (this.total > 5000) {
        this.$message.error('导出功能限制5000条，请筛选后再导出')
        return
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      this.formInline.responseType = true
      exportExchangeInfo(
        this.formInline,
        () => {},
        (res) => {
          this.formInline.responseType = false
          loading.close()
          if (res && res.type === 'application/json') {
            this.$message.error('导出失败')
            return
          }
          this.$message.closeAll()
          const link = document.createElement('a')
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel;charset=utf-8'
          }) // 文件流处理
          link.style.display = 'none' // 去除a标签的样式
          link.href = URL.createObjectURL(blob)
          link.download = `${
            this.addPermission === 'transactionData_addPurchasing'
              ? '采购订单数据'
              : '销售订单数据'
          }_${timestampToTime(new Date().valueOf(), true)}`
          document.body.appendChild(link)
          // 模拟点击事件
          link.click()
          // 移除创建的a标签
          window.URL.revokeObjectURL(link.href)
          document.body.removeChild(link)
        }
      )
    },
    // 当输入提单编号的时候发生的改变  校验是否合规  如果不符合正则 则为空
    enterContent(row) {
      const pre = /^[a-zA-Z0-9-/]*$/g
      if (pre.test(row.deliveryCode)) {
        return
      }
      this.$message.error('提单编号仅支持数字、字母、-、/')
      this.$set(row, 'deliveryCode', '')
    },
    getdata(type) {
      switch (this.$route.path) {
        case '/transactionData/purchasingManagement':
          this.formInline = { ...this.sellFormInline }
          this.formInline.exchangeType = '01'
          break
        case '/transactionData/marketManagement':
          this.formInline = { ...this.purchaseFormInline }
          this.formInline.exchangeType = '02'
          break
      }
      if (!type) {
        this.formInline.pageNum = 1
      } else if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          exchangeType: this.formInline.exchangeType
        }
      }
      pageExchangeInfo(this.formInline, (res) => {
        this.listData = [...res.data.pageData]
        this.total = res.data.total
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.purchasing-management-page-box {
  width: 100%;
  .button-assembly-box {
    text-align: center;
    margin: 24px 0 2px;
  }
  .tip-box {
    padding: 24px 0;
    font-size: 14px;
    color: #666;
    i {
      color: $assistColor4;
      font-size: 16px;
    }
  }
  .no-padding-top {
    padding-top: 0;
  }
}
</style>
